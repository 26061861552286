// Import Vue
import Vue from 'vue';

// Touch
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents, {
  disableClick: true,
  swipeTolerance: 50
});

const Carousel = () => {
  document.querySelectorAll('[data-carousel]').forEach((el) => {
    new Vue({
      el: el,
      data () {
        return {
          count: null,
          visible: 0,
          // autoPlay: false,
          swiped: false
        };
      },
      methods: {
        setIndex (index) {
          return (this.visible = index);
        },
        next () {
        // next (clicked) {
          // if (clicked || this.swiped) {
          //   this.stopAutoPlay();
          // }

          if (this.visible === this.count - 1) {
            return (this.visible = 0);
          }
          this.visible++;
        },
        previous () {
        // previous (clicked) {
          // if (clicked || this.swiped) {
          //   this.stopAutoPlay();
          // }

          if (this.visible === 0) {
            return (this.visible = this.count - 1);
          }
          this.visible--;
        },
        swipeHandler (direction) {
          if (direction === 'left') {
            this.next();
            this.swiped = true;
          } else if (direction === 'right') {
            this.previous();
            this.swiped = true;
          }
        },
        // stopAutoPlay () {
        //   clearInterval(this.autoPlay);

        //   this.autoPlay = false;
        // }
      },
      mounted () {
        const carousel = this.$refs.carousel;

        this.count = parseInt(carousel.dataset.count);

        // this.autoPlay = setInterval(() => {
        //   this.next();
        // }, 10000);
      }
    });
  });
};

export default Carousel;
