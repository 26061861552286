<template>
  <ValidationObserver ref="form" class="w-full" v-slot="{ handleSubmit, invalid }">
    <!-- Form Successful Submitted Message -->
    <div v-if="success" class="flex flex-col items-center justify-center h-full py-40 md:py-46">
      <span class="text-4xl md:text-2.875 font-bold text-blue-500 mb-1">
        Thank You!
      </span>
      <span class="">
        Your message has been sent!
      </span>
    </div>
    <!-- Close Form Successful Submitted Message -->

    <!-- Form -->
    <form
      @submit.prevent="handleSubmit(onSubmit)"
      class="relative py-12 md:py-20 xl:max-w-2xl"
      :class="success ? 'transition-opacity opacity-0 hidden' : 'opacity-100'"
    >
      <!-- Form Fields -->
      <fieldset class="w-10/12 mx-auto mb-5 md:w-7/12 lg:w-10/12 xl:w-8/12">
        <!-- First Name -->
        <ValidationProvider
          name="First Name"
          rules="required"
          v-slot="{ errors }"
          class="relative block mb-4"
        >
          <label
            :class="[errors.length > 0 && clicked ? 'text-red-500' : 'text-gray-700']"
            class="text-sm"
            for="firstname"
          >
            First Name
          </label>

          <input
            v-model="user.firstname"
            type="text"
            id="firstname"
            name="firstname"
            :class="[
              errors.length > 0 && clicked
                ? 'text-red-500 border-red-500 focus:border-red-500 placeholder-red-500'
                : ' border-transparent',
            ]"
            class="w-full bg-white rounded-md appearance-none focus:border-transparent focus:ring-transparent"
          />

          <span
            v-if="errors.length > 0 && clicked"
            class="absolute right-0 block w-full text-xs font-normal text-right text-red-500 top-1"
          >
            {{ errors[0] }}.
          </span>
        </ValidationProvider>
        <!-- Close First Name -->

        <!-- Last Name -->
        <ValidationProvider
          name="Last Name"
          rules="required"
          v-slot="{ errors }"
          class="relative block mb-4"
        >
          <label
            :class="[errors.length > 0 && clicked ? 'text-red-500' : 'text-gray-700']"
            class="text-sm"
            for="lastname"
          >
            Last Name
          </label>

          <input
            v-model="user.lastname"
            type="text"
            id="lastname"
            name="lastname"
            :class="[
              errors.length > 0 && clicked
                ? 'text-red-500 border-red-500 focus:border-red-500 placeholder-red-500'
                : ' border-transparent',
            ]"
            class="w-full bg-white rounded-md appearance-none focus:border-transparent focus:ring-transparent"
          />

          <span
            v-if="errors.length > 0 && clicked"
            class="absolute right-0 block w-full text-xs font-normal text-right text-red-500 top-1"
          >
            {{ errors[0] }}.
          </span>
        </ValidationProvider>
        <!-- Close Last Name -->

        <!-- Email -->
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
          class="relative block mb-4"
        >
          <label
            :class="[errors.length > 0 && clicked ? 'text-red-500' : 'text-gray-700']"
            class="text-sm"
            for="contact-email"
          >
            Email Address
          </label>

          <input
            v-model="user.email"
            type="email"
            id="contact-email"
            name="email"
            :class="[
              errors.length > 0 && clicked
                ? 'text-red-500 border-red-500 focus:border-red-500 placeholder-red-500'
                : ' border-transparent',
            ]"
            class="w-full bg-white rounded-md appearance-none focus:border-transparent focus:ring-transparent"
          />

          <span
            v-if="errors.length > 0 && clicked"
            class="absolute right-0 block w-full text-xs font-normal text-right text-red-500 top-1"
          >
            {{ errors[0] }}.
          </span>
        </ValidationProvider>
        <!-- Close Email -->

        <!-- Message -->
        <ValidationProvider
          name="Message"
          rules="required"
          v-slot="{ errors }"
          class="relative block"
        >
          <label
            :class="[errors.length > 0 && clicked ? 'text-red-500' : 'text-gray-700']"
            class="text-sm"
            for="contact-message"
          >
            Message
          </label>

          <textarea
            v-model="user.message"
            id="contact-message"
            name="message"
            :class="[
              errors.length > 0 && clicked
                ? 'text-red-500 border-red-500 focus:border-red-500 placeholder-red-500'
                : ' border-transparent',
            ]"
            class="w-full bg-white rounded-md appearance-none focus:border-transparent focus:ring-transparent"
            ref="textarea"
            rows="6"
            @input="resize"
          >
          </textarea>
          <span
            v-if="errors.length > 0 && clicked"
            class="absolute right-0 block w-full text-xs font-normal text-right text-red-500 top-1"
          >
            {{ errors[0] }}.
          </span>
        </ValidationProvider>
        <!-- Close Message -->

        <!-- WP Nonce -->
        <input type="hidden" :value="security" name="security" />
      </fieldset>
      <!-- Close Form Fields -->

      <!-- Submit Button -->
      <div
        v-on:click="clicked = true"
        class="flex flex-col w-10/12 mx-auto transition-opacity duration-1000 md:flex-row lg:flex-row-reverse md:w-7/12 lg:w-10/12 xl:w-8/12"
        :class="success ? 'opacity-0' : 'opacity-100'"
      >
        <!-- CTA -->
        <button
          type="submit"
          :class="invalid ? 'cursor-not-allowed' : 'button-hover-orange'"
          class="py-3.5 mr-5 lg:mr-0 lg:ml-5 self-start text-lg bg-blue-500 text-white rounded-xl px-16 focus:outline-none"
        >
          Send
        </button>

        <!-- Error Message -->
        <div v-if="invalid && clicked" class="mt-4 text-sm text-red-500 md:mt-0">
          <p>
            Oops, something's not right! Please fill in the required field(s).
          </p>

          <p v-if="message && !success">
            {{ message }}
          </p>
        </div>
      </div>
      <!-- Close Submit Button -->
    </form>
    <!-- Close Form -->
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// No message specified.
extend('email', email);
extend('required', {
  ...required,
  message: '{_field_} is required',
});
export default {
  name: 'Contact',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    user: {},
    message: '',
    security: tofinoJS.nextNonce,
    success: null,
    clicked: false,
  }),
  methods: {
    onSubmit () {
      // alert('Form Submitted!');
      // this.success = true;
      fetch(tofinoJS.ajaxUrl, {
        method: 'post',
        body: new URLSearchParams({
          action: 'ajax-contact',
          security: this.security,
          data: JSON.stringify(this.user),
        }),
      })
        .then(async response => {
          const data = await response.json();

          if (!data.success) {
            this.success = false;

            if (data.type === 'validation') {
              // Assign errors from backend validation
              this.$refs.form.setErrors(data.extra);
            }
          } else {
            this.success = true;

            this.message = data.message;
          }
        })
        .catch(error => {
          console.error(error);
          this.message = 'A system error has occured. Please try again later.';
        });
    },
    resize (e) {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight + 1}px`;
    },
  },
};
</script>
