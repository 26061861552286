// Import Font loader
import WebFont from 'webfontloader';

// Import Vue
import Vue from "vue";

// Import Alert
import Alert from '../modules/alert';

// Menu
import Menu from '../modules/menu';

// Carousel
import Carousel from '../modules/carousel';

// Accordion
import Accordion from '../../vue/Accordion';

// Contact Form
import Contact from "../../vue/Contact";

// Sharelinks
import Sharelinks from 'sharelinks';

export default {
  init () {
    // JavaScript to be fired on all pages

    // Load Fonts
    WebFont.load({
      google: {
        families: ['Roboto:wght@300;400;500;700'],
        display: 'swap',
        version: 2
      }
    });

    // Alert
    if (document.body.contains(document.getElementById('tofino-notification'))) {
      Alert();
    }

    // Menu
    if (document.body.contains(document.getElementById('main-menu'))) {
      Menu();
    }

    // Marquee Arrow Down
    const scrollButtons = document.querySelectorAll(".js-arrow-down");

    // Loop through the elements
    for (const button of scrollButtons) {
      button.addEventListener("click", e => {

        const targetMarqueeHeight = document.getElementById("js-marquee-height");
        const marqueeHeight = targetMarqueeHeight.offsetHeight;

        const targetNavHeight = document.getElementById("js-nav-height");
        const navHeight = targetNavHeight.offsetHeight;

        const totalHeight = marqueeHeight + navHeight;

        window.scrollTo({
          top: totalHeight,
          behavior: "smooth"
        });
      });
    }

    // Carousels
    Carousel();

    // Accordion
    document.querySelectorAll(".js-accordion").forEach((el) => {
      new Vue({
        el: el,
        components: {
          Accordion
        }
      });
    });

    // Contact Form
    document.querySelectorAll(".js-contact").forEach(el => {
      new Vue({
        el: el,
        components: {
          Contact
        }
      });
    });

    // Sharelinks
    new Sharelinks('.share');

  },
  finalize () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  loaded () {
    // Javascript to be fired on page once fully loaded
  },
};
