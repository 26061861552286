<template>
  <div>
    <h3
      @click="toggle"
      class="flex items-center justify-between cursor-pointer">
        {{ title }}
      <span
        v-if="active"
        class="self-center ml-6 text-3xl">
        —
      </span>
      <span
        v-else
        class="self-center ml-6 text-3xl">
          +
      </span>
    </h3>

    <slot v-if="active"></slot>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data() {
    return {
       active: false
    };
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    toggle() {
      this.active = !this.active;
    }
  }
}
</script>