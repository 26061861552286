var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h3",
        {
          staticClass: "flex items-center justify-between cursor-pointer",
          on: { click: _vm.toggle }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          _vm.active
            ? _c("span", { staticClass: "self-center ml-6 text-3xl" }, [
                _vm._v("\n      —\n    ")
              ])
            : _c("span", { staticClass: "self-center ml-6 text-3xl" }, [
                _vm._v("\n        +\n    ")
              ])
        ]
      ),
      _vm._v(" "),
      _vm.active ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }